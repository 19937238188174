<template>
	<b-modal id="edit-user" size="lg" title="Edit User" ref="modal" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs content-class="mt-3">
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Company:">
										<v-select name="Company" class="style-chooser" label="text"
											:options="companyOptions" :reduce="(company) => company.value"
											v-model="selCompany" v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Company')" class="help-block">{{
											errors.first('Company')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="First Name:" label-for="firstName" description>
										<b-form-input name="First Name" type="text" v-model="form.firstName" v-validate="{
											required: true,
											regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
										}" placeholder="First Name" maxlength="25" />
										<span v-show="errors.has('First Name')" class="help-block">{{
											errors.first('First Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Middle Name:" label-for="middleName" description>
										<b-form-input name="Middle Name" type="text" v-model="form.middleName" v-validate="{
											required: false,
											regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
										}" placeholder="Middle Name" maxlength="25" />
										<span v-show="errors.has('Middle Name')" class="help-block">{{
											errors.first('Middle Name')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="12" sm="12">
									<b-form-group label="Last Name:" label-for="lastName" description>
										<b-form-input name="Last Name" type="text" v-model="form.lastName" v-validate="{
											required: true,
											regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
										}" placeholder="Last Name" maxlength="25" />
										<span v-show="errors.has('Last Name')" class="help-block">{{
											errors.first('Last Name')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Job Position:" label-for="position" description>
										<b-form-input name="Position" type="text" v-model="form.position"
											v-validate="'required'" placeholder />
										<span v-show="errors.has('Position')" class="help-block">{{
											errors.first('Position')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Employee No:" label-for="employeeNo" description>
										<b-form-input name="Employee No" type="text" v-model="form.employeeNo" v-validate="{
											required: false,
											regex: /^([A-Za-z0-9\s\-]{1,25})$/,
										}" placeholder="Employee No" maxlength="12" />
										<span v-show="errors.has('Employee No')" class="help-block">{{
											errors.first('Employee No')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Access Type:" label-for="type" description>
										<b-form-select name="Type" v-model="form.type" v-validate="'required'"
											:options="userTypes" class="mb-3" />
										<span v-show="errors.has('Type')" class="help-block">{{
											errors.first('Type')
										}}</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Contact No:" label-for="contactNo" description>
										<b-form-input name="Contact No" type="text" v-model="form.contactNo" v-validate="{
											required: contactNoIsRequired,
											regex: '^[+]639[0-9]{9}$',
										}" placeholder="+639xxxxxxxxx" />
										<span v-show="errors.has('Contact No')" class="help-block">
											{{ 'Contact No must follow this format: +639xxxxxxxxx' }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Account">
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Email Address:" label-for="emailAddress"
										description="Email address will be used as the username of this user's account">
										<b-form-input name="Email Address" type="text" v-model="form.emailAddress"
											placeholder="Email Address" :readonly="true" autocomplete="off" />
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Password:" label-for="password" description>
										<b-button variant="link"
											v-b-tooltip.hover.top="'Send reset password link to the user\'s email'"
											@click="sendResetPassword" class="mr-1">
											Reset Password
										</b-button>
										<br />
										<b-button variant="link"
											v-b-tooltip.hover.top="'Set the user\'s account to NEW USER status to allow update password on next login'"
											@click="revertToNewAccount" class="mr-1">
											Reset to New Account
										</b-button>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscription">
							<b-row class="my-2">
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Status">
										<b-form-radio-group v-model="form.isActive" :options="statusOptions"
											name="isActive" />
									</b-form-group>
								</b-col>

								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Has Notification?">
										<b-form-radio-group v-model="form.hasNotif" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Enable 2-Way Auth?">
										<b-form-radio-group v-model="form.has2WayAuth" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>

		<template #modal-footer="{ ok, cancel }">
			<b-button variant="secondary" @click="cancel()">Cancel</b-button>
			<b-button variant="primary" @click="ok()"> Update </b-button>
		</template>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import userApi from '@/api/userApi';

// Others
import { firebase } from '@/config/firebase';
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'edit-user',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				id: '',
				firstName: '',
				middleName: '',
				lastName: '',
				company: '',
				companyId: '',
				position: '',
				employeeNo: '',
				type: null,
				contactNo: '',
				emailAddress: '',
				isActive: 'true',
				hasNotif: false,
				has2WayAuth: false,
				isNewUser: true,
				companyAccess: [],
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},
			userTypes: config.userTypes,
			yesNoOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],
			statusOptions: config.statusOptions,

			selCompany: config.companyDefaultValue,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		contactNoIsRequired() {
			return this.form.has2WayAuth;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.firstName + ' ' + this.form.lastName;
		}
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			} else if (
				UserUtil.exceedMaximum(
					this.allCompaniesObj[this.selCompany.id],
					this.allUsersObj,
					this.form
				)
			) {
				this.$toaster.warning(
					'You have exceeded the allowed no of active users for this company.'
				);
				this.isLoading = false;
				return;
			} else if (
				!UserUtil.allowManagerAccount(
					this.form,
					this.allUsersObj,
				)
			) {
				this.$toaster.warning(
					'You can only have 1 manager account within a company.'
				);
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		getUserObject(param) {
			return {
				id: param.id,
				firstName: param.firstName,
				middleName: param.middleName,
				lastName: param.lastName,
				company: param.company,
				companyId: param.companyId,
				position: param.position,
				employeeNo: param.employeeNo,
				type: param.type,
				emailAddress: param.emailAddress,
				contactNo: param.contactNo,
				isActive: param.isActive,
				isNewUser: param.isNewUser,
				isPasswordExpired: param.isPasswordExpired,
				passwordExpirationDate: param.passwordExpirationDate,
				hasNotif: param.hasNotif,
				has2WayAuth: param.has2WayAuth,
				companyAccess: param.companyAccess,
				dateCreated: parseInt(param.dateCreated),
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			this.processFormValues();

			try {
				let userObj = this.getUserObject(this.form);
				let { data } = await userApi.updateUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseEditUser', data.user);
					this.$refs.modal.hide();
				} else {
					let error = data.errors[0];
					if (
						(!_.isEmpty(error.code) &&
							error.code === 'auth/email-already-exists') ||
						error.code === 'auth/phone-number-already-exists'
					) {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating user "${this.name}". Please try again.`
				);
			}
		},
		processFormValues() {
			// Removes excess whitespace
			this.form.firstName = ValidationUtil.removeExcessWhiteSpace(
				this.form.firstName
			);
			this.form.lastName = ValidationUtil.removeExcessWhiteSpace(
				this.form.lastName
			);
			this.form.middleName = ValidationUtil.removeExcessWhiteSpace(
				this.form.middleName
			);
			this.form.position = ValidationUtil.removeExcessWhiteSpace(
				this.form.position
			);

			// Assign the selected company to the user
			this.updateCompanySelection();
		},
		updateCompanySelection() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
		},

		sendResetPassword() {
			firebase
				.auth()
				.sendPasswordResetEmail(this.form.emailAddress)
				.then(() => {
					this.$toaster.success("Reset Password Link was sent to user's email");
				})
				.catch((_error) => {
					this.$toaster.error(
						"There was a problem encountered sending the reset password link to user's email."
					);
				});
		},

		async revertToNewAccount() {
			try {
				// show loading indicator
				this.isLoading = true;

				let userObj = this.getUserObject(this.form);

				let { data } = await userApi.resetToNewUser(
					userObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.form = data.user;
					this.$toaster.success(data.message);
				} else {
					let error = data.errors[0];
					this.$toaster.error(error);
				}

			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error user "${this.name}" reset to new state. Please try again.`
				);
			}
		},

		async onReset() {
			// init form
			let user = this.$store.getters.currUser;
			let userJSON = JSON.stringify(user);
			this.form = JSON.parse(userJSON);

			// init subscription
			this.form.hasNotif = user.hasNotif ? true : false;
			this.form.has2WayAuth = user.has2WayAuth ? true : false;

			// init company access
			if (_.isEmpty(this.form.companyAccess)) {
				this.form.companyAccess = await UserUtil.getDefaultCompanyAccess(
					this.form,
					this.allCompaniesObj
				);
			}

			// init company
			let companyId = user.companyId;
			this.selCompany = DropDownItemsUtil.getCompanyItem(
				this.allCompaniesObj[companyId]
			);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style></style>

