<template>
	<div>
		<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage"
			:filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

			<template v-slot:cell(dateCreated)="row">{{
				getFormattedDateWithTime(row.item.dateCreated)
			}}</template>

			<template v-slot:cell(old)="row">
				{{
					row.item.old ? row.item.old.company : '-'
				}}
			</template>

			<template v-slot:cell(new)="row">
				{{
					row.item.new ? row.item.new.company : '-'
				}}
			</template>

		</b-table>

		<b-row>
			<b-col md="8" sm="12" class="my-1">
				<span class="totalDisplay">Total: {{ totalRows }}</span>
			</b-col>
			<b-col md="4" sm="12" class="my-1">
				<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
					class="my-0" />
			</b-col>
		</b-row>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// API & Database
import AuditTrailLogsDAO from '@/database/auditTrailLogs';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import _ from 'lodash';

export default {
	name: 'user-change-company-logs',
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{ key: 'dateCreated', label: 'Date Updated' },
				{ key: 'old', label: 'Previous Company' },
				{ key: 'new', label: 'Current Company' },
				{ key: 'createdBy', label: 'Updated By' },
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
		};
	},
	mounted() {
		let userId = this.row.item.id;
		this.retrieveChangeLog(userId);
	},
	methods: {
		async retrieveChangeLog(userId) {
			if (userId) {
				let param = {
					collection: 'users',
					id: userId,
				};
				let logsObj = await AuditTrailLogsDAO.getAuditTrailLogs(param);

				this.items = [];
				_.forEach(logsObj, log => {
					if (!log.old || log.old.company !== log.new.company) {
						this.items.push(log);
					}
				});
				this.totalRows = this.items.length;
			}
		},

		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
}
</script>

<style scoped></style>
