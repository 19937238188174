<template>
	<b-modal
		id="import-company-access"
		size="lg"
		title="Import Company Access"
		ok-title="Import"
		ref="modal"
		@cancel="onReset"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-container fluid>
			<b-row class="mb-2">
				<b-col sm="12">
					<i class="icon-info"></i> &nbsp;
					<i> Select 1 template to import from the table. </i>
				</b-col>
			</b-row>

			<b-row class="mx-1">
				<b-table
					ref="companiesTable"
					show-empty
					striped
					hover
					:items="items"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:sort-direction="sortDirection"
					responsive
					selectable
					select-mode="single"
					@row-selected="onRowSelected"
					selected-variant="primary"
				>
					<template v-slot:cell(selected)="{ rowSelected }">
						<template v-if="rowSelected">
							<span aria-hidden="true">&check;</span>
							<span class="sr-only">Selected</span>
						</template>
						<template v-else>
							<span aria-hidden="true">&nbsp;</span>
							<span class="sr-only">Not selected</span>
						</template>
					</template>

					<template v-slot:cell(companyAccess)="row">
						<div>
							<div class="mb-2">
								<a
									:ref="'toggle-' + row.item.id"
									v-b-toggle="'collapse-' + row.item.id"
									@click="onToggle(row.item)"
								>
									View More
								</a>
							</div>

							<b-collapse :id="'collapse-' + row.item.id">
								<b-badge
									class="mr-2"
									variant="dark"
									v-for="company in row.item.companyAccess"
									:key="company.id"
								>
									{{ company.name }}&nbsp;
								</b-badge>
							</b-collapse>
						</div>
					</template>
				</b-table>
			</b-row>
			<b-row>
				<b-col sm="6" class="text-sm-right">
					<b-input-group prepend="Show" append="/ Page" size="sm">
						<b-form-select :options="pageOptions" v-model="perPage" />
					</b-input-group>
				</b-col>
				<b-col sm="6">
					<b-pagination
						align="right"
						:total-rows="totalRows"
						:per-page="perPage"
						v-model="currentPage"
						size="sm"
					/>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
// DAO
import templateDAO from '@/database/companyAccessTemplates';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'import-company-access',
	components: {
		Loading,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					label: 'Template Name',
					sortable: true,
				},
				{
					key: 'description',
					label: 'Description',
					sortable: true,
				},
				{
					key: 'companyAccess',
					label: 'Company Access',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// for filtering
			filterBy: {
				name: '',
			},

			allTemplatesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	watch: {
		'filterBy.name'(value) {
			this.onSearch();
		},
	},
	async mounted() {
		await this.retrieveTemplates(this.loggedUser.companyId);
	},
	methods: {
		onRowSelected(items) {
			this.selected = items;
		},
		async retrieveTemplates(companyId) {
			if (this.isSuperAdmin) {
				this.allTemplatesObj = await templateDAO.getAllCompanyAccessTemplates();
			} else {
				this.allTemplatesObj = await templateDAO.getCompanyAccessTemplates(
					companyId
				);
			}

			this.items = Object.values(this.allTemplatesObj);
			this.totalRows = this.items.length;

			for (const item of this.items) {
				item.toggled = false;
			}
		},
		handleOk(evt) {
			if (_.isEmpty(this.selected)) {
				// Prevent modal from closing
				evt.preventDefault();

				this.$toaster.warning(
					'Please select a template to import from the table'
				);
				return;
			} else {
				this.$refs.modal.hide();
				this.$emit('onSelCompanyAccessTemplate', this.selected[0]);
			}
		},
		onReset() {
			this.allTemplatesObj = {};
		},
		onToggle(item) {
			item.toggled = !item.toggled;
			this.$refs['toggle-' + item.id].text = item.toggled
				? 'View Less'
				: 'View More';
		},
	},
};
</script>>

<style scoped>
</style>